
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DeviceDetail } from '../../types/devices'
import { deviceStatusMap, allocateStatusMap } from '@/utils/dict'

@Component
export default class Base extends Vue {
  @Prop() readonly deviceId!: string;

  private isEdit = false
  private editErr = ''
  deviceStatusMap = deviceStatusMap
  allocateStatusMap = allocateStatusMap
  detail: DeviceDetail = {
    deviceId: '',
    deviceTypeName: '',
    deviceName: '',
    deviceTypeId: '',
    deviceCode: '',
    deviceNumber: '',
    supplierId: '',
    deviceModel: '',
    deviceStatus: '',
    runStatus: '',
    projectName: '',
    isAllocated: ''
  }

  created () {
    this.loadData()
  }

  loadData () {
    this.$axios.get(this.$apis.devices.selectDeviceByDeviceId, {
      deviceId: this.deviceId
    }).then(res => {
      this.detail = res
    })
  }

  onSave () {
    if (this.detail.deviceName) {
      this.editErr = ''
      this.$axios.post(this.$apis.devices.updateDeviceSelective, {
        deviceId: this.deviceId,
        deviceName: this.detail.deviceName
      }).then(() => {
        this.$message({ message: '保存成功', type: 'success' })
        this.isEdit = false
      })
    } else {
      this.editErr = '请输入设备名称'
    }
  }
}
